// ==========================================================================
// DDIGITAL - HEAD.JS
// ==========================================================================

window.DDIGITAL = window.DDIGITAL || {};

// We no longer need Modernizr,
// as all our polyfills are already supported in modern browsers
// Just make sure we flip the class for JS support
document.documentElement.classList.remove('supports-no-js');
document.documentElement.classList.add('supports-js');

require('./util/typekit');

require('./libs/_ie.js');
require('./libs/_isReady.js');
