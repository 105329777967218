(function() {
	document.documentElement.classList.add('wf-active');
	if (!document.querySelector('link[href="https://use.typekit.net/ope2hqh.css"]')) {
		var link = document.createElement('link');
		link.rel = 'stylesheet';
		link.type = 'text/css';
		link.href = 'https://use.typekit.net/ope2hqh.css';
		document.head.appendChild(link);
	}
}());

